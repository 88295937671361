import React, { useState, useEffect } from 'react';

const MyBlogs = () => {
    const [contentfulData, setContentfulData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchContentfulData = async () => {
            try {
                const response = await fetch(
                    'https://cdn.contentful.com/spaces/joe3dedpft8q/environments/master/entries?access_token=_ZfWlmiDNz0D85P7jD0oUYUyG7ZGryEajvtoEiu2568'
                );

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setContentfulData(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchContentfulData();
    }, []);

    const renderBlogContent = (body) => {
        if (!body || !body.content) return null;

        return body.content.map((node, index) => {
            switch (node.nodeType) {
                case 'heading-1':
                    return <h1 key={index} className="text-3xl font-bold mt-6 mb-4">{node.content[0].value}</h1>;
                case 'heading-2':
                    return <h2 key={index} className="text-2xl font-bold mt-5 mb-3">{node.content.map(content => content.value).join('')}</h2>;
                case 'heading-3':
                    return <h3 key={index} className="text-xl font-semibold mt-4 mb-2">{node.content[0].value}</h3>;
                case 'paragraph':
                    return (
                        <p key={index} className="mb-4 text-gray-700">
                            {node.content.map((content, i) => {
                                if (content.nodeType === 'hyperlink') {
                                    return (
                                        <a 
                                            key={i}
                                            href={content.data.uri}
                                            className="text-blue-600 hover:text-blue-800 underline"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {content.content[0].value}
                                        </a>
                                    );
                                }
                                return <span key={i} className={content.marks?.some(mark => mark.type === 'bold') ? 'font-bold' : ''}>{content.value}</span>;
                            })}
                        </p>
                    );
                default:
                    return null;
            }
        });
    };

    return (
        <div className="p-8 max-w-7xl mx-auto">
            <div className="bg-white rounded-lg shadow-lg p-6">
                {loading ? (
                    <div className="text-center py-8">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mx-auto"></div>
                        <p className="mt-4 text-gray-600">Loading blog content...</p>
                    </div>
                ) : error ? (
                    <div className="text-red-600 text-center py-8">Error: {error}</div>
                ) : contentfulData && contentfulData.items.length > 0 ? (
                    <article className="prose lg:prose-xl max-w-none">
                        {/* Blog Header */}
                        <header className="mb-8 border-b pb-6">
                            <h1 className="text-4xl font-bold text-gray-900 mb-4">
                                {contentfulData.items[0].fields.title}
                            </h1>
                            {contentfulData.includes?.Asset && (
                                <img 
                                    src={`https:${contentfulData.includes.Asset[0].fields.file.url}`}
                                    alt={contentfulData.includes.Asset[0].fields.title}
                                    className="mb-6 rounded-lg shadow-md max-w-md mx-auto"
                                />
                            )}
                        </header>

                        {/* Blog Content */}
                        <div className="blog-content">
                            {renderBlogContent(contentfulData.items[0].fields.body)}
                        </div>

                        {/* Blog Footer */}
                        <footer className="mt-8 pt-6 border-t text-gray-600">
                            <p>Last updated: {new Date(contentfulData.items[0].sys.updatedAt).toLocaleDateString()}</p>
                        </footer>
                    </article>
                ) : (
                    <div className="text-center py-8 text-gray-600">No blog posts found.</div>
                )}

                {/* JSON Dump Section (hidden by default, uncomment for debugging) */}
                {/* <div className="mt-8 border-t pt-6">
                    <h2 className="text-2xl font-bold text-gray-800 mb-4">Raw JSON Data</h2>
                    <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
                        <code className="text-sm">
                            {JSON.stringify(contentfulData, null, 2)}
                        </code>
                    </pre>
                </div> */}
            </div>
        </div>
    );
};

export default MyBlogs;