import React, { createContext, useContext, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  GoogleAuthProvider, 
  signInWithPopup, 
  signOut, 
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth';
import axios from 'axios';
import config from './config';

const firebaseConfig = {
  apiKey: "AIzaSyDc9kw6TCmFZfKLQcAdG1aTAXcabTa0mYs",
  authDomain: "auth.loomos.co",
  projectId: "webdemo-c14f1",
  appId: "1:258839155127:web:695f651f7a37108a639832"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
      await axios.post(`${config.API_BASE_URL}/signin`, { id_token: idToken });
      setUser(result.user);
      setError(null);
    } catch (error) {
      setError('Error signing in with Google. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignUp = async (email, password) => {
    setLoading(true);
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      const idToken = await result.user.getIdToken();
      await axios.post(`${config.API_BASE_URL}/signin`, { id_token: idToken });
      setUser(result.user);
      setError(null);
      return { success: true };
    } catch (error) {
      let errorMessage = 'Error creating account. Please try again.';
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'An account with this email already exists.';
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'Password should be at least 6 characters.';
      }
      setError(errorMessage);
      return { success: false, error: errorMessage };
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignIn = async (email, password) => {
    setLoading(true);
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await result.user.getIdToken();
      await axios.post(`${config.API_BASE_URL}/signin`, { id_token: idToken });
      setUser(result.user);
      setError(null);
      return { success: true };
    } catch (error) {
      let errorMessage = 'Error signing in. Please check your credentials.';
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        errorMessage = 'Invalid email or password.';
      }
      setError(errorMessage);
      return { success: false, error: errorMessage };
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return { success: true, message: 'Password reset email sent!' };
    } catch (error) {
      let errorMessage = 'Error sending password reset email.';
      if (error.code === 'auth/user-not-found') {
        errorMessage = 'No account found with this email.';
      }
      return { success: false, error: errorMessage };
    }
  };

  const handleSignOut = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      setUser(null);
      setError(null);
    } catch (error) {
      setError('Error signing out. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    error,
    loading,
    handleGoogleSignIn,
    handleEmailSignUp,
    handleEmailSignIn,
    handlePasswordReset,
    handleSignOut
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
