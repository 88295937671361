import React, { useState, useEffect, useRef } from 'react';
import { ChevronRight, Globe, Mic, Image, ArrowRight, Play, Pause, Edit, Palette, Maximize2, Minimize2, Menu, X, Check, Mail } from 'lucide-react';
import { useAuth } from '../AuthProvider';
import { useNavigate, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Link as ScrollLink, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import VideoComparison from './VideoComparison';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './LandingPage.css'; // Add this import at the top of the file
import axios from 'axios';
import config from '../config';
import useTawkTo from '../hooks/useTawkTo';

const MagicWandIcon = ({ className = '', size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-10 -10 120 120"
    width={size}
    height={size}
    className={className}
  >
    <g transform="rotate(90, 50, 50)">
      <path
        d="M50 15 L50 85 M15 50 L85 50 M25 25 L75 75 M75 25 L25 75"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M75 75 L95 95"
        stroke="currentColor"
        strokeWidth="10" 
        strokeLinecap="round"
      />
    </g>
  </svg>
);

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-arrow-next`}
      onClick={onClick}
    />
  );
}

const AuthModal = ({ 
  showAuthModal, 
  setShowAuthModal, 
  authMode, 
  setAuthMode, 
  email, 
  setEmail, 
  password, 
  setPassword, 
  authError, 
  setAuthError,
  showForgotPassword,
  setShowForgotPassword,
  resetEmail,
  setResetEmail,
  resetMessage,
  setResetMessage
}) => {
  const { handleEmailSignIn, handleEmailSignUp, handleGoogleSignIn, handlePasswordReset } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAuthError('');
    
    const result = await (authMode === 'signin' 
      ? handleEmailSignIn(email, password)
      : handleEmailSignUp(email, password));
      
    if (!result.success) {
      setAuthError(result.error);
    } else {
      setShowAuthModal(false);
    }
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setResetMessage({ type: '', message: '' });
    
    const result = await handlePasswordReset(resetEmail);
    if (result.success) {
      setResetMessage({ 
        type: 'success', 
        message: 'Check your email for password reset instructions!' 
      });
      // Clear the email field after successful submission
      setResetEmail('');
      // Optionally, automatically return to login after a delay
      setTimeout(() => {
        setShowForgotPassword(false);
        setResetMessage({ type: '', message: '' });
      }, 3000);
    } else {
      setResetMessage({ 
        type: 'error', 
        message: result.error 
      });
    }
  };

  if (showForgotPassword) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-2xl p-8 max-w-md w-full mx-4 shadow-xl">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-3xl font-bold text-gray-900">Reset Password</h2>
            <button 
              onClick={() => {
                setShowForgotPassword(false);
                setResetMessage({ type: '', message: '' });
              }}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <X size={24} />
            </button>
          </div>

          {resetMessage.message && (
            <div className={`p-4 mb-6 rounded-md ${
              resetMessage.type === 'success' 
                ? 'bg-green-50 border-l-4 border-green-400 text-green-700'
                : 'bg-red-50 border-l-4 border-red-400 text-red-700'
            }`}>
              <p className="text-sm">{resetMessage.message}</p>
            </div>
          )}

          <form onSubmit={handleForgotPasswordSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Email Address
              </label>
              <input
                type="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors bg-white text-gray-900 placeholder-gray-400"
                placeholder="Enter your email"
                required
              />
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-300"
            >
              Send Reset Link
            </button>
          </form>

          <div className="mt-6 text-center">
            <button
              onClick={() => {
                setShowForgotPassword(false);
                setResetMessage({ type: '', message: '' });
              }}
              className="text-blue-600 hover:text-blue-800 font-medium"
            >
              Back to Sign In
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-2xl p-8 max-w-md w-full mx-4 shadow-xl">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">
            {authMode === 'signin' ? 'Welcome Back' : 'Get Started for Free'}
          </h2>
          <button 
            onClick={() => setShowAuthModal(false)}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            <X size={24} />
          </button>
        </div>
        
        {authError && (
          <div className="bg-red-50 border-l-4 border-red-400 p-4 mb-6 rounded-md">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{authError}</p>
              </div>
            </div>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Email Address
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors bg-white text-gray-900 placeholder-gray-400"
              placeholder="Enter your email"
              required
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Password
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors bg-white text-gray-900 placeholder-gray-400"
              placeholder={authMode === 'signin' ? 'Enter your password' : 'Create a password'}
              required
            />
            {authMode === 'signin' && (
              <div className="mt-2 text-right">
                <button
                  type="button"
                  className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowForgotPassword(true);
                    setResetEmail(email); // Pre-fill the email if it exists
                  }}
                >
                  Forgot password?
                </button>
              </div>
            )}
          </div>
          
          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-300"
          >
            {authMode === 'signin' ? 'Sign In' : 'Create Free Account'}
          </button>
        </form>
        
        <div className="mt-6 text-center">
          <button
            onClick={() => setAuthMode(authMode === 'signin' ? 'signup' : 'signin')}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            {authMode === 'signin' 
              ? "Don't have an account? Try for free" 
              : "Already have an account? Sign in"}
          </button>
        </div>
        
        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-4 bg-white text-gray-500">Or continue with</span>
            </div>
          </div>
          
          <button
            type="button"
            onClick={handleGoogleSignIn}
            className="mt-4 w-full flex items-center justify-center px-4 py-3 border border-gray-300 rounded-lg shadow-sm bg-white text-gray-700 hover:bg-gray-50 transition-colors duration-300"
          >
            <img
              className="h-5 w-5 mr-2"
              src="https://www.svgrepo.com/show/475656/google-color.svg"
              alt="Google logo"
            />
            <span className="font-medium">Continue with Google</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const LandingPage = () => {
  const { handleGoogleSignIn } = useAuth();
  const navigate = useNavigate();  
  const beforeVideoRef = useRef(null);
  const afterVideoRef = useRef(null);
  const [currentUseCase, setCurrentUseCase] = useState(0);
  const useCases = [
    "Product Demos",
    "Training Videos",
    "How-to Guides"
  ];

  const [isWiderView, setIsWiderView] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [plans, setPlans] = useState([]);
  const { user } = useAuth();

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useTawkTo();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const idToken = await user?.getIdToken();
        const plansResponse = await axios.get(`${config.API_BASE_URL}/subscription-plans`, {
          headers: { Authorization: `Bearer ${idToken}` }
        });
        const sortedPlans = plansResponse.data.plans.sort((a, b) => {
          if (a.name.toLowerCase().includes('free')) return -1;
          if (b.name.toLowerCase().includes('free')) return 1;
          return a.price - b.price;
        });
        setPlans(sortedPlans);
      } catch (error) {
        console.error('Failed to fetch plans:', error);
      }
    };

    fetchPlans();
  }, [user]);

  const getPlanName = (tier) => {
    switch (tier.toLowerCase()) {
      case 'free(with watermark)':
        return 'Free Plan';
      case 'starter':
        return 'Starter';
      case 'pro':
        return 'Pro';
      case 'pro-max':
        return 'Pro Max';
      case 'business':
        return 'Business';
      default:
        return tier;
    }
  };

  useEffect(() => {
    // Update font loading to include Poppins
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap'; // Add the font link
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Apply font to body
    document.body.style.fontFamily = "'Poppins', sans-serif";

    // Cleanup
    return () => {
      document.head.removeChild(link);
      document.body.style.fontFamily = '';
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUseCase((prev) => (prev + 1) % useCases.length);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Initialize scrollSpy
    Events.scrollEvent.register('begin', function() {
      // Remove console.log
    });

    Events.scrollEvent.register('end', function() {
      // Remove console.log
    });

    scrollSpy.update();

    // Cleanup
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  useEffect(() => {
    // Add meta tags for SEO
    document.title = "Create Stunning Videos with Loomos | AI Video Editing, Multilingual Support, and Easy Sharing";
    const metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = "Transform your screen recordings into polished, multilingual videos with Loomos. Experience AI-powered video editing, easy sharing, and no editing skills required!";
    document.head.appendChild(metaDescription);

    // Cleanup
    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  useEffect(() => {
    // Add Google Analytics script
    const gtagScript1 = document.createElement('script');
    gtagScript1.async = true;
    gtagScript1.src = 'https://www.googletagmanager.com/gtag/js?id=G-X3QPY1M2BF';
    
    const gtagScript2 = document.createElement('script');
    gtagScript2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-X3QPY1M2BF');
    `;

    document.head.appendChild(gtagScript1);
    document.head.appendChild(gtagScript2);

    // Cleanup
    return () => {
      document.head.removeChild(gtagScript1);
      document.head.removeChild(gtagScript2);
    };
  }, []);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const endDate = new Date('2024-12-01T00:00:00'); // Set your sale end date
      const difference = endDate - new Date();

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft();

    return () => clearInterval(timer);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSignIn = async () => {
    try {
      await handleGoogleSignIn();
      // Track successful login
      if (window.gtag) {
        window.gtag('event', 'login', {
          'method': 'Google',
          'success': true
        });
      }
      navigate('/');
    } catch (error) {
      console.error('Error signing in:', error);
      // Track failed login
      if (window.gtag) {
        window.gtag('event', 'login', {
          'method': 'Google',
          'success': false,
          'error': error.message
        });
      }
    }
  };

  const languages = [
    { code: 'en', name: 'English', flag: '🇺🇸' },
    { code: 'hi', name: 'Hindi', flag: '🇮🇳' },
    { code: 'es', name: 'Spanish', flag: '🇪🇸' },
    { code: 'fr', name: 'French', flag: '🇫🇷' },
  ];

  const beforeVideoSrc = `${process.env.PUBLIC_URL}/demomedia/before/sheet.mov`;
  const beforeThumbnailSrc = `${process.env.PUBLIC_URL}/demomedia/before/sheet.jpg`;
  const getAfterVideoSrc = (langCode) => `${process.env.PUBLIC_URL}/demomedia/after/${langCode}-porwal.mp4`;
  const getAfterThumbnailSrc = (langCode) => `${process.env.PUBLIC_URL}/demomedia/after/en-porwal-thumbnail.jpg`;

  const features = [
    {
      icon: Edit,
      title: "AI-Powered Transcript Editing",
      desc: "Our AI cleans up 'uhms' and improves grammar for a polished result.",
      media: `${process.env.PUBLIC_URL}/demomedia/features/transcript.mp4`
    },
    {
      icon: Image,
      title: "Aesthetic Background Images",
      desc: "Enhance your videos with beautiful background images to create a polished look.",
      media: `${process.env.PUBLIC_URL}/demomedia/features/bg.gif`
    },
    {
      icon: Globe,
      title: "Multilingual Translation & AI Voiceovers",
      desc: "Translate and choose from professional AI voiceovers in multiple languages and accents.",
      media: `${process.env.PUBLIC_URL}/demomedia/features/voice.gif`
    }
  ];

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
    customPaging: function(i) {
      return (
        <div className="custom-dot"></div>
      );
    },
  };

  const fadeInOut = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.5 }
  };

  const PricingSection = () => (
    <Element name="pricing">
      <section id="pricing" className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-6 max-w-7xl mx-auto">
            {plans.map((plan, index) => (
              <div
                key={plan.id}
                className="bg-white rounded-lg shadow-lg p-8 flex flex-col relative overflow-hidden"
              >
                {!plan.name.toLowerCase().includes('free') && (
                  <div className="absolute -top-2 -right-2">
                    <div className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-blue-900 px-12 py-2 transform rotate-0 font-bold shadow-lg">
                      50% OFF
                    </div>
                  </div>
                )}
                <h3 className="text-2xl md:text-3xl font-bold mb-4 text-[#0f172a]">{getPlanName(plan.name)}</h3>
                <p className="text-3xl md:text-4xl font-bold mb-6 text-[#3b82f6]">
                  {!plan.name.toLowerCase().includes('free') && (
                    <>
                      <span className="text-xl line-through text-gray-400 mr-2">
                        ${plan.price * 2}
                      </span>
                      <span className="text-blue-600">${plan.price}</span>
                    </>
                  )}
                  {plan.name.toLowerCase().includes('free') && `$${plan.price}`}
                  <span className="text-sm font-normal text-[#64748b]">
                    {plan.name.toLowerCase().includes('free') ? '' : ' one-time payment'}
                  </span>
                </p>
                <ul className="mb-8 flex-grow">
                  {plan.description.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center mb-3">
                      <Check size={20} className="text-green-500 mr-2 flex-shrink-0" />
                      <span className="text-[#334155] font-light">{feature}</span>
                    </li>
                  ))}
                </ul>
                {!plan.name.toLowerCase().includes('free') && (
                  <div className="text-center mb-4">
                    <p className="text-sm font-semibold text-yellow-600">
                      Save ${plan.price} Today!
                    </p>
                    <p className="text-xs text-blue-600">
                      Limited Time Offer
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="mt-12">
            <div className="bg-gradient-to-r from-blue-600 to-blue-700 p-8 rounded-xl shadow-lg max-w-3xl mx-auto text-center">
              <h4 className="text-white text-xl mb-2 font-medium">Ready to get started?</h4>
              <p className="text-blue-100 mb-6">Try Loomos free and upgrade anytime</p>
              <button 
                onClick={() => setShowAuthModal(true)}
                className="bg-white text-blue-600 px-12 py-4 rounded-full text-lg font-bold hover:bg-blue-50 transition duration-300 shadow-lg hover:shadow-xl"
              >
                Try Free
              </button>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );

  const VoiceCloningSection = () => (
    <section id="voice-cloning" className="py-24 bg-[#f0f4ff]">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-6 text-[#1e293b]">
          Exciting News Coming This November!
        </h2>
        <p className="text-lg md:text-xl mb-4 text-[#334155]">
          We are launching a voice cloning feature that allows you to create final content or product demo videos in your own voice, with studio-grade quality. Remove the 'uhms' and the un-natural pauses from your transcript and make your videos a lot more engaging.
        </p>
        <p className="text-lg md:text-xl mb-4 text-[#334155]">
          This feature will be available for beta users this month. 
        </p>
        <a 
          href="https://docs.google.com/forms/d/e/1FAIpQLSe6pvYSPAohKPHgGLs03nhjWkLJFZKan8ANcrMBWe9YReYMEg/viewform?usp=sf_link" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-block bg-[#3b82f6] text-white px-6 py-3 rounded-md font-semibold hover:bg-[#2563eb] transition duration-300"
        >
          Sign Up for Beta Access
        </a>
      </div>
    </section>
  );

  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState('signin'); // 'signin' or 'signup'
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState({ type: '', message: '' });

  return (
    <div className="bg-white text-[#1e293b] min-h-screen overflow-x-hidden font-['Poppins',sans-serif] relative">
      {/* Navigation */}
      <motion.nav 
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled ? 'bg-white/80 backdrop-blur-md shadow-md' : 'bg-transparent'
        }`}
      >
        <div className="max-w-custom mx-auto px-4 lg:px-6 lg:px-8"> {/* Updated class here */}
          <div className={`flex justify-between items-center transition-all duration-300 ${
            isScrolled ? 'py-4' : 'py-6'
          }`}>
            <div className="flex-shrink-0 flex items-center">
              <MagicWandIcon size={isScrolled ? 32 : 40} className="text-[#3b82f6]" />
              <h1 className={`font-bold bg-gradient-to-r from-[#3b82f6] to-[#2563eb] text-transparent bg-clip-text transition-all duration-300 ${
                isScrolled ? 'text-2xl' : 'text-3xl'
              }`}>
                loomos
              </h1>
            </div>
            <div className="hidden md:flex space-x-8">
              <ScrollLink 
                to="product-demo" 
                spy={true}
                smooth={true} 
                duration={500} 
                offset={-100}
                activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold"
              >
                Product Demo
              </ScrollLink>
              <ScrollLink 
                to="features" 
                spy={true}
                smooth={true} 
                duration={500} 
                offset={-100}
                activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold"
              >
                Features
              </ScrollLink>
              <ScrollLink 
                to="pricing" 
                spy={true}
                smooth={true} 
                duration={500}
                offset={-100}
                activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold"
              >
                Pricing
              </ScrollLink>
            </div>
            <div className="flex items-center space-x-4">
              <button
                className="hidden md:block bg-white text-[#3b82f6] border border-[#3b82f6] rounded-md font-semibold transition-all duration-300 hover:bg-blue-50 px-6 py-3"
                onClick={() => {
                  setAuthMode('signin');
                  setShowAuthModal(true);
                }}
              >
                Login
              </button>
              <button
                className="hidden md:block bg-[#3b82f6] text-white rounded-md font-semibold transition-all duration-300 hover:bg-[#2563eb] px-6 py-3"
                onClick={() => {
                  setAuthMode('signup');
                  setShowAuthModal(true);
                }}
              >
                Try For Free
              </button>
              <button
                className="md:hidden text-[#334155]"
                onClick={toggleMenu}
              >
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile menu */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="md:hidden bg-white py-4"
            >
              <div className="flex flex-col space-y-4 px-4">
                <ScrollLink 
                  to="product-demo" 
                  spy={true}
                  smooth={true} 
                  duration={500} 
                  offset={-100}
                  activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                  className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold" 
                  onClick={() => setIsMenuOpen(false)}
                >
                  Product Demo
                </ScrollLink>
                <ScrollLink 
                  to="features" 
                  spy={true}
                  smooth={true} 
                  duration={500} 
                  offset={-100}
                  activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                  className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold" 
                  onClick={() => setIsMenuOpen(false)}
                >
                  Features
                </ScrollLink>
                <ScrollLink 
                  to="pricing" 
                  spy={true}
                  smooth={true} 
                  duration={500}
                  offset={-100}
                  activeClass="text-[#3b82f6] font-bold bg-[#3b82f6]/10 px-3 py-2 rounded-md"
                  className="text-[#334155] hover:text-[#3b82f6] transition duration-300 cursor-pointer px-3 py-2 font-bold" 
                  onClick={() => setIsMenuOpen(false)}
                >
                  Pricing
                </ScrollLink>

                {/* Add divider */}
                <div className="border-t border-gray-200 my-2"></div>

                {/* Add Login and Try For Free buttons */}
                <div className="flex flex-col space-y-3 px-3">
                  <button
                    className="w-full bg-white text-[#3b82f6] border border-[#3b82f6] rounded-md font-semibold transition-all duration-300 hover:bg-blue-50 py-3"
                    onClick={() => {
                      setAuthMode('signin');
                      setShowAuthModal(true);
                      setIsMenuOpen(false);
                    }}
                  >
                    Login
                  </button>
                  <button
                    className="w-full bg-[#3b82f6] text-white rounded-md font-semibold transition-all duration-300 hover:bg-[#2563eb] py-3"
                    onClick={() => {
                      setAuthMode('signup');
                      setShowAuthModal(true);
                      setIsMenuOpen(false);
                    }}
                  >
                    Try For Free
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.nav>

      {/* Add padding to the top of the first section to account for the fixed navbar */}
      <div className={`transition-all duration-300 ${isScrolled ? 'pt-24' : 'pt-28'}`}></div>

      {/* Hero Section */}
      <section className="py-6 md:py-12 overflow-hidden relative">
        <motion.div 
          initial="hidden"
          animate="visible"
          variants={staggerChildren}
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10"
        >
          <motion.h1 
            variants={fadeIn} 
            className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold mb-12 leading-tight tracking-tight text-[#1e293b]"
            style={{ lineHeight: '1.2' }} 
          >
            Beautiful <span className="text-[#3b82f6]">Screen Recordings</span> in Minutes
          </motion.h1>

          {/* Features and Button Section */}
          <div className="space-y-12">
            {/* Features row - now with responsive grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 px-4 max-w-4xl mx-auto">
              <div className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-50 to-white rounded-full shadow-sm hover:shadow-md transition-all duration-300">
                <Edit size={20} className="text-[#3b82f6] mr-2 flex-shrink-0" />
                <span className="text-sm font-medium text-[#1e293b] whitespace-nowrap">Edit the Transcript</span>
              </div>
              <div className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-50 to-white rounded-full shadow-sm hover:shadow-md transition-all duration-300">
                <MagicWandIcon size={20} className="text-[#3b82f6] mr-2 flex-shrink-0" />
                <span className="text-sm font-medium text-[#1e293b] whitespace-nowrap">Remove the 'Uhm's</span>
              </div>
              <div className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-50 to-white rounded-full shadow-sm hover:shadow-md transition-all duration-300">
                <Mic size={20} className="text-[#3b82f6] mr-2 flex-shrink-0" />
                <span className="text-sm font-medium text-[#1e293b] whitespace-nowrap">Use AI Voiceovers</span>
              </div>
              <div className="flex items-center px-4 py-2 bg-gradient-to-r from-blue-50 to-white rounded-full shadow-sm hover:shadow-md transition-all duration-300">
                <Globe size={20} className="text-[#3b82f6] mr-2 flex-shrink-0" />
                <span className="text-sm font-medium text-[#1e293b] whitespace-nowrap">Translate to 20+ languages</span>
              </div>
            </div>

            {/* Button on next line */}
            <div className="flex justify-center px-4">
              <motion.button 
                onClick={() => setShowAuthModal(true)}
                className="bg-gradient-to-r from-[#3b82f6] to-[#2563eb] text-white px-8 sm:px-10 py-3 sm:py-4 rounded-full text-base sm:text-lg font-bold hover:shadow-lg transition-all duration-300"
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0 10px 20px rgba(59, 130, 246, 0.2)"
                }}
                whileTap={{ scale: 0.95 }}
              >
                Get Started Now
              </motion.button>
            </div>

            {/* Company Logos Section */}
            <div className="max-w-4xl mx-auto">
              <div className="flex flex-wrap justify-center items-center gap-8 md:gap-16">
                <a href="https://elevenlabs.io/" target="_blank" rel="noopener noreferrer">
                  <img 
                    src="/images/eleven.svg" 
                    alt="ElevenLabs" 
                    className="h-10 md:h-12 object-contain opacity-80 hover:opacity-100 transition-opacity"
                  />
                </a>
                <a href="https://www.producthunt.com/products/loomos/reviews" target="_blank" rel="noopener noreferrer">
                  <img 
                    src="/images/rating.svg" 
                    alt="Rating" 
                    className="h-10 md:h-12 object-contain opacity-80 hover:opacity-100 transition-opacity"
                  />
                </a>
                <a href="https://www.producthunt.com/products/loomos" target="_blank" rel="noopener noreferrer">
                  <img 
                    src="/images/ph.svg" 
                    alt="Product Hunt" 
                    className="h-10 md:h-12 object-contain opacity-80 hover:opacity-100 transition-opacity"
                  />
                </a>
              </div>
            </div>
          </div>
        </motion.div>
      </section>

      {/* Video Comparison Carousel */}
      <Element name="product-demo">
        <section id="product-demo" className="bg-white">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="slider-container relative -mt-20"
          >              
            <div>
              <div className="bg-white rounded-2xl overflow-hidden mb-8">
                <VideoComparison 
                  languages={languages}
                  beforeVideoSrc={beforeVideoSrc}
                  beforeThumbnailSrc={beforeThumbnailSrc}
                  getAfterVideoSrc={getAfterVideoSrc}
                  getAfterThumbnailSrc={getAfterThumbnailSrc}
                  title=""
                  titleBarColor="#ffffff"
                  titleTextColor="#0f172a"
                  buttonColor="#3b82f6"
                  buttonTextColor="white"
                  buttonHoverColor="#2563eb"
                  beforeLabel={
                    <div className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-full shadow-sm">
                      <span className="w-2 h-2 bg-gray-400 rounded-full"></span>
                      <span className="font-medium text-gray-600">Raw Recording</span>
                    </div>
                  }
                  afterLabel={
                    <div className="flex items-center space-x-2 px-4 py-2 border border-blue-300 rounded-full shadow-sm">
                      <span className="w-2 h-2 bg-blue-500 rounded-full animate-pulse"></span>
                      <span className="font-medium text-blue-700">Loomos Magic ✨</span>
                    </div>
                  }
                />
              </div>
            </div>                              
          </motion.div>          
        </section>
      </Element>

      {/* Horizontal Line */}
      {/* <hr className="max-w-custom mx-auto border-t border-gray-300 my-8" style={{ width: '85%' }} /> Ensure this line is styled correctly */}

      {/* Features Section */}
      <Element name="features">
        <section id="features" className="pt-0 pb-8 bg-white">
          <div className="max-w-6xl mx-auto px-2 sm:px-4 lg:px-6">
            <motion.h3 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-4xl md:text-5xl font-bold text-center mb-6 text-[#0f172a]"
            >
              <span className="text-[#3b82f6]">Engaging</span> screen recordings.<br/>Created in minutes.
            </motion.h3>
            <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl md:text-2xl text-center mb-16 text-[#64748b] max-w-3xl mx-auto font-light">
            Thousands of people use Loomos to record product demos, training videos, tutorials and social media posts.
          </motion.p>
            <motion.div 
              variants={staggerChildren}
              initial="hidden"
              whileInView="visible"
              className="space-y-24"
            >
              {features.map((feature, index) => (
                <motion.div 
                  key={index} 
                  variants={fadeIn}
                  className={`flex flex-col md:flex-row items-center ${
                    index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
                  }`}
                >
                  <div className={`w-full md:w-1/2 ${
                    index % 2 === 0 ? 'md:pr-12' : 'md:pl-12'
                  }`}>
                    <div className="flex items-center space-x-3 mb-4">
                      <feature.icon className="w-12 h-12 text-indigo-600 flex-shrink-0" />
                      <h4 className="text-2xl md:text-3xl font-semibold text-[#0f172a]">{feature.title}</h4>
                    </div>
                    <p className="text-lg text-[#334155] font-light">{feature.desc}</p>
                  </div>
                  <div className="w-full md:w-1/2 mt-8 md:mt-0">
                    <div className="rounded-lg overflow-hidden shadow-lg">
                      {feature.media.endsWith('.gif') ? (
                        <img 
                          src={feature.media} 
                          alt={`Feature: ${feature.title}`} // Example alt text
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <video 
                          src={feature.media} 
                          autoPlay 
                          muted 
                          loop 
                          className="w-full h-full object-cover"
                        />
                      )}
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </section>
      </Element>

      {/* How It Works Section */}
      {/* <Element name="how-it-works">
        <section id="how-it-works" className="py-24 bg-[#eff1ff]">
          <div className="max-w-4xl mx-auto px-2 sm:px-4 lg:px-6">
            <motion.h3 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-4xl md:text-5xl font-bold text-center mb-16 text-[#1e293b]"
            >
              <span className="text-[#3b82f6]">Effortless</span> Video Creation in Three Steps
            </motion.h3>
            <motion.div 
              variants={staggerChildren}
              initial="hidden"
              whileInView="visible"
              className="space-y-12"
            >
              {[
                { step: 1, title: "Upload Your Own Video or Provide a Loom Recording Link", desc: "Choose your preferred method to bring your content to life" },
                { step: 2, title: "Select Voice & Edit Transcript", desc: "Choose the voice and edit the transcript with cleaned up 'uhms' and improved grammar" },
                { step: 3, title: "Download & Share", desc: "Get your polished video with professional, human-like AI voiceovers, then download and share" },
              ].map((item, index) => (
                <motion.div key={index} variants={fadeIn} className="flex items-center">
                  <div className="flex-shrink-0 mr-8">
                    <div className="w-16 h-16 rounded-full bg-[#3b82f6] text-white flex items-center justify-center text-2xl font-bold">
                      {item.step}
                    </div>
                  </div>
                  <div>
                    <h4 className="text-2xl md:text-3xl font-semibold mb-2 text-[#1e293b]">{item.title}</h4>
                    <p className="text-lg text-[#334155] font-light">{item.desc}</p>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </section>
      </Element> */}

      {/* Loom Integration Highlight
      <section className="py-20 bg-white">
        <div className="max-w-4xl mx-auto px-2 sm:px-4 lg:px-6 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h3 className="text-3xl md:text-4xl font-bold mb-6 text-[#0f172a]">
              <span className="text-[#3b82f6]">Elevate</span> Your Loom Recordings
            </h3>
            <p className="text-xl md:text-2xl mb-8 text-[#334155] font-light">
              Transform your Loom screen recordings into polished, professional videos with just a few clicks. Seamlessly import and enhance your content.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowAuthModal(true)}
              className="bg-[#3b82f6] text-white px-8 py-3 rounded-full text-lg font-bold hover:bg-[#2563eb] transition duration-300"
            >
              Try Free
            </motion.button>
          </motion.div>
        </div>
      </section> */}

      {/* Pricing Section */}
      <PricingSection />

      {/* Call to Action */}
      {/* <section className="py-32 bg-[#1e3a8a]">
        <motion.div 

          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center"
        >
          <h3 className="text-4xl md:text-5xl font-bold mb-6 text-white">
            <span className="text-[#60a5fa]">Revolutionize</span> Your Video Communication
          </h3>
          <p className="text-xl md:text-2xl mb-10 text-gray-300 max-w-2xl mx-auto font-light">
            Start for free and pay only for what you use. No subscriptions, no commitments.
          </p>
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowAuthModal(true)}
            className="bg-[#60a5fa] text-[#1e3a8a] px-10 py-4 rounded-full text-xl font-bold hover:bg-white transition duration-300"
          >
            Get Loomos for Free
          </motion.button>
        </motion.div>
      </section> */}

      {/* Voice Cloning Section */}
      {/* <VoiceCloningSection /> */}

      {/* Footer */}
      <footer className="bg-[#f8fafc] text-[#334155]">
        <div className="max-w-custom mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex flex-col items-center space-y-6 md:space-y-0 md:flex-row md:justify-between md:items-center">
            {/* Logo and email */}
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <MagicWandIcon size={24} className="text-[#3b82f6]" />
                <h1 className="font-bold bg-gradient-to-r from-[#3b82f6] to-[#2563eb] text-transparent bg-clip-text text-xl">
                  loomos
                </h1>
              </div>
              <a 
                href="mailto:help@loomos.co" 
                className="flex items-center space-x-1 text-sm text-gray-600 hover:text-[#3b82f6] transition duration-300"
              >
                <Mail size={14} />
                <span>help@loomos.co</span>
              </a>
            </div>

            {/* Copyright text */}
            <p className="text-sm text-center">&copy; 2024 Loomos. All rights reserved.</p>

            {/* Links */}
            <div className="flex items-center space-x-8">
              <Link to="/privacy" className="text-sm hover:text-[#3b82f6] transition duration-300">
                Privacy
              </Link>
              <a 
                href="https://loomos.lemonsqueezy.com/affiliates" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-sm hover:text-[#3b82f6] transition duration-300"
              >
                Become an Affiliate
              </a>
            </div>
          </div>
        </div>
      </footer>

      {showAuthModal && (
        <AuthModal 
          showAuthModal={showAuthModal}
          setShowAuthModal={setShowAuthModal}
          authMode={authMode}
          setAuthMode={setAuthMode}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          authError={authError}
          setAuthError={setAuthError}
          showForgotPassword={showForgotPassword}
          setShowForgotPassword={setShowForgotPassword}
          resetEmail={resetEmail}
          setResetEmail={setResetEmail}
          resetMessage={resetMessage}
          setResetMessage={setResetMessage}
        />
      )}
    </div>
  );
};

export default LandingPage;
