import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Check, ChevronRight } from 'lucide-react';
import axios from 'axios';
import { useAuth } from '../AuthProvider';
import config from '../config';

const getPlanName = (tier) => {
  switch (tier.toLowerCase()) {
    case 'free(with watermark)':
      return 'Free Plan';
    case 'starter':
      return 'Starter';
    case 'pro':
      return 'Pro';
    case 'pro-max':
      return 'Pro Max';
    case 'business':
      return 'Business';
    default:
      return tier;
  }
};

const PlanCard = ({ plan, isCurrentPlan, onUpgrade, isFreeTier, isEnterprise }) => (
  <motion.div
    className={`bg-white rounded-2xl p-8 shadow-lg h-full flex flex-col justify-between relative overflow-hidden ${
      isCurrentPlan ? 'border-2 border-indigo-500 shadow-indigo-100' : ''
    }`}
    whileHover={{ scale: 1.03, boxShadow: '0 8px 30px rgba(0,0,0,0.12)' }}
    transition={{ type: 'spring', stiffness: 300 }}
  >
    {!isFreeTier && !isEnterprise && (
      <div className="absolute -top-2 -right-2">
        <div className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-blue-900 px-8 py-2 transform rotate-45 translate-x-8 translate-y--8 font-bold shadow-lg">
          50% OFF
        </div>
      </div>
    )}
    <div>
      <h3 className="text-2xl font-bold mb-2">{getPlanName(plan.name)}</h3>
      <p className="text-4xl font-bold mb-6">
        {isFreeTier ? 'Free' : (isEnterprise ? 'Custom' : (
          <>
            <span className="text-2xl line-through text-gray-400 mr-2">
              ${plan.price * 2}
            </span>
            <span className="text-blue-600">${plan.price}</span>
            <span className="text-base font-normal text-gray-500"> one-time payment</span>
          </>
        ))}
      </p>
      <ul className="mb-8 space-y-3">
        {plan.description.map((feature, index) => (
          <li key={index} className="flex items-start">
            <Check size={20} className="text-green-500 mr-2 flex-shrink-0 mt-1" />
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
    {isFreeTier ? (
      <p className="text-sm text-gray-500 text-center">Default plan for new users</p>
    ) : isEnterprise ? (
      <a 
        href="https://cal.com/loomos"
        target="_blank"
        rel="noopener noreferrer"
        className="w-full py-3 px-6 rounded-lg text-sm font-medium flex items-center justify-center bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-md hover:shadow-lg"
      >
        Contact Us
        <ChevronRight size={18} className="ml-2" />
      </a>
    ) : (
      <>
        <motion.button
          className={`w-full py-3 px-6 rounded-lg text-sm font-medium flex items-center justify-center ${
            isCurrentPlan
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-md hover:shadow-lg'
          }`}
          whileHover={isCurrentPlan ? {} : { scale: 1.05 }}
          whileTap={isCurrentPlan ? {} : { scale: 0.98 }}
          onClick={() => !isCurrentPlan && onUpgrade(plan.id)}
          disabled={isCurrentPlan}
        >
          {isCurrentPlan ? 'Current Plan' : 'Claim 50% Off Now'}
          {!isCurrentPlan && <ChevronRight size={18} className="ml-2" />}
        </motion.button>
        {!isCurrentPlan && !isFreeTier && (
          <div className="text-center mt-2">
            <p className="text-sm font-semibold text-yellow-600">
              Save ${plan.price} Today!
            </p>
            <p className="text-xs text-blue-600">
              Black Friday Offer - Limited Time Only
            </p>
          </div>
        )}
      </>
    )}
  </motion.div>
);

const UpgradePlan = () => {
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const calculateTimeLeft = () => {
      const endDate = new Date('2024-12-01T00:00:00');
      const difference = endDate - new Date();

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft();

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const idToken = await user.getIdToken();
        const plansResponse = await axios.get(`${config.API_BASE_URL}/subscription-plans`, {
          headers: { Authorization: `Bearer ${idToken}` }
        });
        setPlans(plansResponse.data.plans);

        const userSubResponse = await axios.get(`${config.API_BASE_URL}/user-subscription`, {
          headers: { Authorization: `Bearer ${idToken}` }
        });
        setCurrentPlan(userSubResponse.data);
      } catch (error) {
        console.error('Failed to fetch plans or user subscription:', error);
      }
    };

    fetchPlans();
  }, [user]);

  const sortedPlans = useMemo(() => {
    if (!plans.length) return [];
    return [...plans].sort((a, b) => {
      if (a.name.toLowerCase().includes('free')) return -1;
      if (b.name.toLowerCase().includes('free')) return 1;
      return a.price - b.price;
    });
  }, [plans]);

  const handleUpgrade = async (planId) => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.post(
        `${config.API_BASE_URL}/create-checkout-session`,
        { variant_id: planId },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Failed to create checkout session:', error);
    }
  };

  const enterprisePlan = {
    id: 'enterprise',
    name: 'Enterprise',
    description: [
      'All Pro features',
      'Custom integrations',
      'Dedicated support',
      'Tailored solutions'
    ]
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-16">
          <div className="inline-block bg-gradient-to-r from-blue-600 to-blue-800 text-white px-8 py-4 rounded-2xl shadow-lg transform hover:scale-105 transition duration-300 border-2 border-yellow-400">
            <div className="bg-yellow-400 text-blue-900 px-4 py-1 rounded-full text-sm font-bold mb-3 inline-block">
              LIMITED TIME OFFER
            </div>
            <h3 className="text-2xl font-bold mb-2">🎉 Black Friday Special - 50% OFF! 🎉</h3>
            <p className="text-lg mb-3">Unlock Premium Features at the Best Price of the Year!</p>
            <div className="flex justify-center space-x-4 text-white">
              <div className="bg-blue-700/50 px-4 py-2 rounded-lg">
                <span className="text-2xl font-bold">{timeLeft.days}</span>
                <p className="text-sm">Days</p>
              </div>
              <div className="bg-blue-700/50 px-4 py-2 rounded-lg">
                <span className="text-2xl font-bold">{timeLeft.hours}</span>
                <p className="text-sm">Hours</p>
              </div>
              <div className="bg-blue-700/50 px-4 py-2 rounded-lg">
                <span className="text-2xl font-bold">{timeLeft.minutes}</span>
                <p className="text-sm">Minutes</p>
              </div>
              <div className="bg-blue-700/50 px-4 py-2 rounded-lg">
                <span className="text-2xl font-bold">{timeLeft.seconds}</span>
                <p className="text-sm">Seconds</p>
              </div>
            </div>
          </div>
        </div>

        <h1 className="text-5xl font-bold mb-4 text-center text-[#0f172a]">
          Choose Your Plan
        </h1>
        <p className="text-xl text-gray-600 text-center mb-12">
          Unlock the full potential of your videos with our premium features
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {sortedPlans.map((plan) => (
            <div key={plan.id} className="h-full">
              <PlanCard
                plan={plan}
                isCurrentPlan={currentPlan && currentPlan.plan_id === plan.id}
                onUpgrade={handleUpgrade}
                isFreeTier={plan.name.toLowerCase().includes('free')}
              />
            </div>
          ))}
          <div className="h-full">
            <PlanCard
              plan={enterprisePlan}
              isCurrentPlan={false}
              onUpgrade={() => {}}
              isFreeTier={false}
              isEnterprise={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlan;
