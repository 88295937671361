import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Upload, Edit, Video, Play, Link as LinkIcon } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import Step1FileUpload from './Step1FileUpload';
import ProcessingStatus from './ProcessingStatus';
import config from '../config';
import useTawkTo from '../hooks/useTawkTo';

const NewProject = ({ user, closeSidePanel, openSidePanel }) => {
  // Add this near the top of the component
  useTawkTo();
  
  const [currentStep, setCurrentStep] = useState(1);
  const [uploadedVideoId, setUploadedVideoId] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [processingStatus, setProcessingStatus] = useState('');
  const [projectTitle, setProjectTitle] = useState('Enter Project Title here ...');
  const [transcript, setTranscript] = useState([]);
  const [loomUrl, setLoomUrl] = useState('');
  const [isUploadingLoom, setIsUploadingLoom] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    if (user) {
      fetchUserSubscription();
    }
  }, [user]);

  const fetchUserSubscription = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/user-subscription`, {
        headers: { Authorization: `Bearer ${idToken}` }
      });
      setSubscription(response.data);
    } catch (error) {
      console.error('Failed to fetch user subscription:', error);
    }
  };

  const MAX_FILE_SIZE = 400 * 1024 * 1024; // 400MB in bytes
  const MAX_DURATION = 10 * 60; // 10 minutes in seconds

  const handleCancelUpload = () => {
    setUploadedVideoId('');
  };

  const handleFileUpload = async (file) => {
    if (subscription && subscription.videos_left === 0) {
      navigate('/upgrade');
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setUploadError(`File size exceeds the maximum limit of ${MAX_FILE_SIZE / (1024 * 1024)}MB.`);
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);
    setUploadError('');

    const chunkSize = 1024 * 1024; // 1MB chunks
    const chunks = Math.ceil(file.size / chunkSize);
    const uploadId = uuidv4();
    const concurrentUploads = 5; // Number of concurrent uploads

    const idToken = await user.getIdToken();

    const uploadChunk = async (chunkIndex) => {
      const chunk = file.slice(chunkIndex * chunkSize, (chunkIndex + 1) * chunkSize);
      const formData = new FormData();
      formData.append('chunk', chunk);
      formData.append('uploadId', uploadId);
      formData.append('chunkIndex', chunkIndex);
      formData.append('totalChunks', chunks);
      formData.append('originalFileName', file.name);

      try {
        await axios.post(`${config.API_BASE_URL}/upload-chunk`, formData, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            const chunkProgress = (percentCompleted / 100) / chunks;
            setUploadProgress(prev => Math.min(prev + chunkProgress * 90, 90));
          },
        });
      } catch (error) {
        console.error('Error uploading chunk:', error);
        throw error;
      }
    };

    try {
      const uploadPromises = [];
      for (let i = 0; i < chunks; i += concurrentUploads) {
        const chunkPromises = [];
        for (let j = 0; j < concurrentUploads && i + j < chunks; j++) {
          chunkPromises.push(uploadChunk(i + j));
        }
        uploadPromises.push(Promise.all(chunkPromises));
      }

      await Promise.all(uploadPromises);

      setUploadProgress(90);
      const response = await axios.post(`${config.API_BASE_URL}/complete-upload`, { uploadId, originalFileName: file.name }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (response.data.duration > MAX_DURATION) {
        setUploadError(`Video duration exceeds the maximum limit of ${MAX_DURATION / 60} minutes.`);
        resetUploadState();
        return;
      }

      setUploadProgress(100);
      setUploadedVideoId(response.data.video_id);
      setTranscript(response.data.transcript);
      closeSidePanel();
      setCurrentStep(2);
    } catch (error) {
      console.error('Error during upload:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setUploadError(error.response.data.error);
      } else {
        setUploadError('An error occurred during the upload process. Please try again.');
      }
      resetUploadState();
    } finally {
      setIsUploading(false);
    }
  };

  const resetUploadState = () => {
    setUploadedVideoId('');
    setIsUploading(false);
    setUploadProgress(0);
    setTranscript([]);
    setCurrentStep(1);
  };

  const handleLoomUpload = async () => {
    if (subscription && subscription.videos_left === 0) {
      navigate('/upgrade');
      return;
    }

    if (!loomUrl) {
      alert('Please enter a valid Loom URL');
      return;
    }

    setIsUploadingLoom(true);
    setUploadProgress(0);

    try {
      const idToken = await user.getIdToken();
      const formData = new FormData();
      formData.append('loom_url', loomUrl);

      const response = await axios.post(
        `${config.API_BASE_URL}/upload-video`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.file_size > MAX_FILE_SIZE) {
        setUploadError(`File size exceeds the maximum limit of ${MAX_FILE_SIZE / (1024 * 1024)}MB.`);
        resetUploadState();
        return;
      }

      if (response.data.duration > MAX_DURATION) {
        setUploadError(`Video duration exceeds the maximum limit of ${MAX_DURATION / 60} minutes.`);
        resetUploadState();
        return;
      }

      // Simulated progress
      setUploadProgress(50);
      await new Promise(resolve => setTimeout(resolve, 1000));
      setUploadProgress(100);

      setUploadedVideoId(response.data.video_id);
      setTranscript(response.data.transcript);
      closeSidePanel();
      setCurrentStep(2);
    } catch (error) {
      console.error('Error uploading Loom video:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setUploadError(error.response.data.error);
      } else {
        setUploadError('Failed to upload Loom video. Please try again.');
      }
    } finally {
      setIsUploadingLoom(false);
    }
  };

  const resetState = () => {
    setCurrentStep(1);
    setUploadedVideoId('');
    setIsUploading(false);
    setUploadProgress(0);
    setProcessingStatus('');
    setProjectTitle('Project Title Here ...');
    openSidePanel();
  };

  const steps = [
    { icon: <Upload size={20} />, text: "Upload video" },
    { icon: <Edit size={20} />, text: "Customize Audio & Visuals" },
    { icon: <Video size={20} />, text: "Get Studio-Quality Video" },
  ];

  return (
    <div className={`h-screen flex flex-col px-4 sm:px-6 lg:px-8 ${currentStep === 1 ? 'py-6 max-w-3xl' : 'pb-8 max-w-7.5xl'} mx-auto`}>
      <div className={`flex-grow flex flex-col ${currentStep === 1 ? 'items-center justify-start space-y-8 mt-8' : 'items-center justify-center'}`}>
        <AnimatePresence mode="wait">
          {currentStep === 1 && (
            <motion.div
              key="step1"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="w-full space-y-12"
            >
              <div className="bg-white rounded-2xl shadow-lg p-8 border border-gray-100">
                <h2 className="text-3xl font-bold text-[#3C82F6] mb-6 text-center">Upload Your Video</h2>
                <p className="text-sm text-gray-600 mb-4 text-center">Maximum file size: 400MB. Maximum duration: 20 minutes.</p>
                <div className="space-y-6">
                  <div className="max-w-md mx-auto">
                    <Step1FileUpload
                      handleFileUpload={handleFileUpload}
                      isUploading={isUploading}
                      uploadProgress={uploadProgress}
                      uploadedVideoId={uploadedVideoId}
                      handleCancelUpload={handleCancelUpload}
                      user={user}
                      uploadError={uploadError}
                    />
                    <p className="text-sm text-gray-600 mt-3 text-center italic">
                      We clone your voice from this video and the default Polished Video shown on next screen is in your own voice.
                    </p>
                  </div>
                  <div className="flex items-center">
                    <div className="flex-grow border-t border-gray-300"></div>
                    <span className="flex-shrink mx-4 text-sm text-gray-500">or</span>
                    <div className="flex-grow border-t border-gray-300"></div>
                  </div>
                  <div className="space-y-3 max-w-md mx-auto">
                    <h3 className="text-xl font-light text-[#3C82F6] text-center flex items-center justify-center">
                      Use 
                      <svg className="ml-2 mr-2" width="24" height="24" viewBox="0 0 46 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M45.4999 19.6967H31.6393L43.6428 12.9942L41.261 9.00451L29.2575 15.707L36.1871 4.09924L32.0614 1.79595L25.1318 13.4024V0H20.3682V13.4037L13.4372 1.79595L9.31147 4.09924L16.2411 15.7057L4.23898 9.00451L1.85717 12.9942L13.8607 19.6967H0V24.3033H13.8593L1.85717 31.0045L4.23898 34.9942L16.2425 28.293L9.31147 39.9008L13.4372 42.2041L20.3682 30.5963V44H25.1318V30.3093L32.1896 42.1323L36.0577 39.9725L28.9985 28.1482L41.2596 34.9942L43.6414 31.0045L31.6393 24.3033H45.4986V19.6967H45.4999ZM22.75 28.263C19.1732 28.263 16.2735 25.4589 16.2735 22C16.2735 18.5411 19.1732 15.737 22.75 15.737C26.3267 15.737 29.2265 18.5411 29.2265 22C29.2265 25.4589 26.3267 28.263 22.75 28.263Z" fill="#565ADD"/>
                      </svg>
                       Loom URL
                      
                    </h3>
                    <div className="flex items-center space-x-2">
                      <input
                        type="text"
                        placeholder="https://www.loom.com/share/your-video-id"
                        value={loomUrl}
                        onChange={(e) => setLoomUrl(e.target.value)}
                        className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 text-sm"
                      />
                      <button
                        onClick={handleLoomUpload}
                        disabled={isUploadingLoom}
                        className="px-4 py-2 bg-[#3C82F6] text-white rounded-md hover:bg-[#3574DC] focus:outline-none focus:ring-2 focus:ring-[#3C82F6] focus:ring-offset-2 disabled:opacity-50 transition duration-150 ease-in-out text-sm"
                      >
                        {isUploadingLoom ? (
                          <span className="flex items-center">
                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Uploading...
                          </span>
                        ) : (
                          <span className="flex items-center">
                            <LinkIcon size={14} className="mr-1" />
                            Upload
                          </span>
                        )}
                      </button>
                    </div>
                    {isUploadingLoom && (
                      <div className="w-full bg-gray-200 rounded-full h-1.5">
                        <div className="bg-purple-600 h-1.5 rounded-full transition-all duration-300 ease-in-out" style={{ width: `${uploadProgress}%` }}></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl shadow-lg p-8 border border-gray-100">
                <h3 className="text-2xl font-bold text-[#3C82F6] mb-6 text-center">How it works ✨</h3>
                <div className="flex justify-between items-center mb-6">
                  {steps.map((step, index) => (
                    <div key={index} className="flex flex-col items-center w-1/3">
                      <div className="rounded-full p-3 mb-3 bg-gray-100 text-gray-400 transition-all duration-300">
                        {step.icon}
                      </div>
                      <p className="text-center text-sm font-light text-gray-600">{step.text}</p>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center">
                  <Link
                    to="/tutorial"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center w-48 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#3C82F6] hover:bg-[#3574DC] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3C82F6]"
                  >
                    <Play size={16} className="mr-2" /> Watch Tutorial
                  </Link>
                </div>
              </div>
            </motion.div>
          )}

          {currentStep === 2 && (
            <motion.div
              key="step2"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="h-full"
              style={{ width: '100%' }}
            >
              <ProcessingStatus
                user={user}
                uploadedVideoId={uploadedVideoId}
                initialProjectTitle={projectTitle}
                initialTranscript={transcript}
                resetState={resetState}
                openSidePanel={openSidePanel}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {subscription && subscription.videos_left === 0 && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                You have no videos left in your current plan. Please upgrade to continue creating videos.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewProject;
