import React from 'react';
import { motion } from 'framer-motion';
import { Edit, PanelLeftClose } from 'lucide-react';

const EditButton = ({ onClick, isEditPanelVisible }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition-colors font-semibold flex items-center space-x-2 mr-2"
      onClick={onClick}
    >
      {isEditPanelVisible ? <PanelLeftClose size={20} /> : <Edit size={20} />}
      <span>{isEditPanelVisible ? 'Hide Edit Panel' : 'Edit'}</span>
    </motion.button>
  );
};

export default EditButton; 